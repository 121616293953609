<template>
  <PageContainer
    :title="pageContainerConfig.title"
    :back="pageContainerConfig.back"
  >
    <template v-slot:content>
      <div class="page-segment" v-if="loading">
        <div class="page-segment-content">
          <Skeleton
            width="40%"
            height="26px"
            borderRadius="16px"
            class="margin-bottom"
          />
          <Skeleton
            width="100%"
            height="164px"
            borderRadius="16px"
            margin="16px 0"
            v-for="(v, i) in 4"
            :key="i"
          />
        </div>
      </div>

      <div class="page-segment" v-if="conferences.length > 0 && !loading">
        <div class="page-segment-content">
          <div class="heading-small margin-bottom margin-top-xs">
            {{ $translations.conferences['next-conferences'] }}
          </div>
          <ConferenceCard
            class="margin-bottom-xs"
            v-for="(item, index) in conferences"
            :key="index"
            :conference="item"
            @click="goDetailConference"
          />
        </div>
      </div>

      <!-- data zero -->
      <DataZeroSimple
        v-if="conferences.length === 0 && !loading"
        :text="datazero.text"
        :image="datazero.image"
        :buttons="datazero.buttons"
      />
    </template>
  </PageContainer>
</template>

<script>
import {
  UsrConferences,
  ConferenceCard,
  PageContainer,
  NoEvents,
  Skeleton
} from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'

import { SeliaEventsEvent } from '@/types/events'
import ConferencesSettingMixin from '@/mixins/conferences-setting-mixin'

export default {
  components: {
    PageContainer,
    ConferenceCard,
    DataZeroSimple,
    Skeleton
  },
  data () {
    return {
      loading: true,
      conferences: [],
      pageContainerConfig: {
        title: this.$translations.conferences.title,
        back: {
          callback: () => this.$router.push({ name: 'Home' })
        }
      },
      datazero: {
        text: this.$translations.conferences['empty-state'].list,
        image: NoEvents
      }
    }
  },
  mixins: [ConferencesSettingMixin],
  methods: {
    mount () {
      this.getConferences()
    },
    async getConferences () {
      this.loading = true
      const config = {
        statusExclude: ['FINISHED', 'CANCELED'],
        perPage: 100,
        page: 1
      }

      const conferences = await UsrConferences.list(config, true)
        .then((response) => response.data.map(o => {
          return {
            ...o,
            img: o.cover
          }
        }))

      this.conferences = conferences.filter((i) => {
        const secondDate = this.$moment(i.start_date.split('+')[0])
          .tz(this.$store.getters.userTimezone)
          .add(i.duration, 'minute')

        return this.$moment(new Date()).isSameOrBefore(secondDate)
      })
      this.loading = false
    },
    goDetailConference (item) {
      this.$segment.track(SeliaEventsEvent.click_view_event)
      this.$router
        .push({
          name: 'ConferenceDetail',
          params: {
            id: item.id
          }
        })
        .catch(() => {})
    }
  }
}
</script>
