import { mapGetters } from 'vuex'

export default {
  mounted () {
    if (this.settings.conferenceModule) {
      this.mount()
    } else {
      this.$store.watch(
        () => this.settings,
        () => {
          this.mount()
        }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    })
  }
}
